import React, { useState } from "react"

const Iso0 = () => {
  const [autre, setAutre] = useState(false)

  const checkAutre = () => {
    setAutre(autre => true)
  }
  const checkNone = () => {
    setAutre(autre => false)
  }
  return (
    <div className="subService">
      <h3>
        En quel matériau est-ce que la façade est réalisée?
        <span className="obg"> *</span>
      </h3>
      <p>
        <label>
          <input
            type="radio"
            name="materiaux-facade"
            value="briques (non traitées)"
            onClick={() => {
              checkNone()
            }}
            required
          />
          briques (non traitées)
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="materiaux-facade"
            value="façade peinte ou enduit de façade"
            onClick={() => {
              checkNone()
            }}
          />
          façade peinte ou enduit de façade
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="materiaux-facade"
            value="autre"
            onClick={() => {
              checkAutre()
            }}
          />
          autre {autre ? <span className="obg"> *</span> : ""}
        </label>
      </p>
      {autre ? (
        <>
          <input
            type="text"
            name="materiaux-facade-autre"
            placeholder="quel type de matériaux"
          />
        </>
      ) : (
        ""
      )}
      <h3>
        Est-ce que le mur creux a été déjà (partiellement) isolé?
        <span className="obg"> *</span>
      </h3>
      <select name="isolation-partiel" required>
        <option value="non, pas sûr">non / pas sûr(e)</option>
        <option value="oui">oui</option>
      </select>
    </div>
  )
}

export default Iso0
