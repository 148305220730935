import React from "react"

const Multi6 = () => {
  return (
    <div className="subService">
      <span className="attention">
        Attention! Une nouvelle fondation est nécessaire pour maçonner une
        nouvelle façade. Les plaquettes de parement sont une alternative plus
        bon marché et prennent moins d'espace.
      </span>
    </div>
  )
}

export default Multi6
