import React, { useState } from "react"
//component
import Iso0 from "../Projet/Iso0"
const Iso1 = () => {
  const [avant1920, set1920] = useState(false)
  const [iso0, setIso0] = useState(false)

  const check1920 = () => {
    set1920(avant1920 => true)
    setIso0(iso0 => true)
  }
  const checkAutre = () => {
    set1920(avant1920 => false)
    setIso0(iso0 => true)
  }
  return (
    <div className="subService">
      <h3>
        Quand est-ce que la maison a été construite?
        <span className="obg"> *</span>
      </h3>
      <p>
        <label>
          <input
            type="radio"
            name="date-construction"
            value="Avant 1920"
            onClick={() => {
              check1920()
            }}
            required
          />
          Avant 1920
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="date-construction"
            value="Entre 1920 et 1975"
            onClick={() => {
              checkAutre()
            }}
          />
          Entre 1920 et 1975
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="date-construction"
            value="Entre 1976 et 1987"
            onClick={() => {
              checkAutre()
            }}
          />
          Entre 1976 et 1987
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="date-construction"
            value="Après 1987"
            onClick={() => {
              checkAutre()
            }}
          />
          Après 1987
        </label>
      </p>
      {avant1920 ? (
        <>
          <span className="attention">
            Attention! Des bâtiments datant d'avant 1920 n'ont pas de mur creux.
            Si vous voulez quand même isoler les murs, optez pour l'isolation
            par l'intérieur ou l'extérieur de la façade.
          </span>
        </>
      ) : (
        ""
      )}
      {iso0 ? <Iso0 /> : ""}
    </div>
  )
}

export default Iso1
