import React from "react"

const Iso3 = () => {
  return (
    <div className="subService">
      <h3>
        Dans quel type de bâtiment est-ce que les travaux doivent être réalisés?
        <span className="obg"> *</span>
      </h3>
      <select name="type-batiment" aria-label="type" required>
        <option value="Bâtiment en usage / fini">
          Bâtiment en usage / fini
        </option>
        <option value="Nouveau bâtiment">Nouveau bâtiment</option>
        <option value="Rénovation">Rénovation</option>
      </select>
    </div>
  )
}

export default Iso3
