import React, { useState } from "react"
//components
import Iso1 from "../Projet/Iso1"
import Iso2 from "../Projet/Iso2"
import Iso3 from "../Projet/Iso3"
const Isolation = () => {
  const [iso1, setIso1] = useState(false)
  const [iso2, setIso2] = useState(false)
  const [iso3, setIso3] = useState(false)

  const checkIso1 = () => {
    setIso1(iso1 => true)
    setIso2(iso2 => false)
    setIso3(iso3 => false)
  }
  const checkIso2 = () => {
    setIso1(iso1 => false)
    setIso2(iso2 => true)
    setIso3(iso3 => false)
  }
  const checkIso3 = () => {
    setIso1(iso1 => false)
    setIso2(iso2 => false)
    setIso3(iso3 => true)
  }
  return (
    <div className="subService">
      <h3>
        Comment voulez-vous isoler la façade? <span className="obg"> *</span>
      </h3>
      <p>
        <label>
          <input
            type="radio"
            name="comment-isoler"
            value="Isoler murs creux"
            onClick={() => {
              checkIso1()
            }}
            required
          />
          Isoler murs creux
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="comment-isoler"
            value="Isoler par l'extérieur et finir avec un nouveau bardage de façade"
            onClick={() => {
              checkIso2()
            }}
          />
          Isoler par l'extérieur et finir avec un nouveau bardage de façade
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="comment-isoler"
            value="Par l'intérieur en installant des contre-cloisons isolantes"
            onClick={() => {
              checkIso3()
            }}
          />
          Par l'intérieur en installant des contre-cloisons isolantes
        </label>
      </p>
      {iso1 ? <Iso1 /> : iso2 ? <Iso2 /> : iso3 ? <Iso3 /> : ""}
    </div>
  )
}

export default Isolation
