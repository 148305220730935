import React from "react"

const Reno2 = () => {
  return (
    <div className="subService">
      <h3>
        Quelle est la surface de la façade?
        <span className="obg"> *</span>
      </h3>
      <select name="surface-renovation-generale" aria-label="type" required>
        <option value="Moins de 10 m²">Moins de 10 m²</option>
        <option value="Entre 10 et 50 m²">Entre 10 et 50 m²</option>
        <option value="Entre 50 et 100 m²">Entre 50 et 100 m²</option>
        <option value="Plus de 100 m²">Plus de 100 m²</option>
      </select>
      <h3>
        Quel est le matériau principal de la façade?
        <span className="obg"> *</span>
      </h3>
      <select name="materiaux-facade" aria-label="type" required>
        <option value="briques ou carreaux émaillés">
          briques ou carreaux émaillés
        </option>
        <option value="briques">briques</option>
        <option value="crépi">crépi</option>
        <option value="enduit de façade">enduit de façade</option>
        <option value="pierre naturelle">pierre naturelle</option>
        <option value="bois">bois</option>
        <option value="métal">métal</option>
        <option value="béton">béton</option>
      </select>
      <h3>
        Est-ce que la façade est peinte? <span className="obg"> *</span>
      </h3>
      <select name="peinture-facade" aria-label="type" required>
        <option value="oui, la peinture doit être enlevée">
          oui, la peinture doit être enlevée
        </option>
        <option value="oui, la peinture ne peut pas être endommagée">
          oui, la peinture ne peut pas être endommagée
        </option>
        <option value="oui, la façade doit aussi être peinte">
          oui, la façade doit aussi être peinte
        </option>
        <option value="non">non</option>
      </select>
    </div>
  )
}

export default Reno2
