import React from "react"

const Paint3 = () => {
  return (
    <div className="subService">
      <h3>
        Quelle est la superficie du mur où il faut appliquer du liège projeté ?
        <span className="obg"> *</span>
      </h3>
      <select name="surface-liege" aria-label="type" required>
        <option value="Moins de 10 m²">Moins de 10 m²</option>
        <option value="Entre 10 et 50 m²">Entre 10 et 50 m²</option>
        <option value="Entre 50 et 100 m²">Entre 50 et 100 m²</option>
        <option value="Plus de 100 m²">Plus de 100 m²</option>
      </select>
      <h3>
        Voulez-vous aussi faire isoler les murs?
        <span className="obg"> *</span>
      </h3>
      <p>
        <label>
          <input
            type="radio"
            name="isoler-mur"
            value="oui / peut-être"
            required
          />
          oui / peut-être
        </label>
      </p>
      <p>
        <label>
          <input type="radio" name="isoler-mur" value="non" required />
          non
        </label>
      </p>
    </div>
  )
}

export default Paint3
