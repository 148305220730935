import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
//data
const getData = graphql`
  {
    c: contentfulConfig {
      devisLogo {
        fixed(height: 100) {
          ...GatsbyContentfulFixed
        }
      }
      devisText
      devisLink
    }
  }
`
const LinkPrime = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="fieldset LinkPrime">
      <a
        href={data.c.devisLink}
        target="_blank"
        rel="noreferrer"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Image fixed={data.c.devisLogo.fixed} />
        <p>{data.c.devisText}</p>
      </a>
    </div>
  )
}

export default LinkPrime
