import React from "react"

const Validation = () => {
  return (
    <div className="fieldset">
      <div className="element">
        <label htmlFor="condition">
          <input
            type="checkbox"
            name="condition"
            aria-label="condition"
            id="condition"
            value="condition acceptée"
            required
          />
          Je donne la permission de traiter mes données comme décrit dans la
          politique de confidentialité et d’accepter les conditions générales.
          <span className="obg"> *</span>
        </label>
      </div>
      <div className="element">
        <label htmlFor="mailing">
          <input
            type="checkbox"
            name="mailing"
            aria-label="mailing"
            id="mailing"
            value="mailing acceptée"
          />
          Je souhaite être informé par e-mail de l’état de ma demande et des
          messages relatifs à Zimo.
        </label>
      </div>
    </div>
  )
}

export default Validation
