import React from "react"
//component
import Multi from "../Projet/Multi"
const Iso2 = () => {
  return (
    <div className="subService">
      <Multi />
    </div>
  )
}

export default Iso2
