import React from "react"

const Travaux = () => {
  return (
    <div className="fieldset">
      <h3>
        Quand voulez-vous faire effectuer les travaux ?
        <span className="obg"> *</span>
      </h3>
      <div className="element">
        <p>
          <select name="travaux-quand" aria-label="heure" required>
            <option value="Aussi vite que possible">
              Aussi vite que possible
            </option>
            <option value="Dans les 2 mois">Dans les 2 mois</option>
            <option value="Dans les 6 mois">Dans les 6 mois</option>
            <option value="En discussion avec l’entrepreneur">
              En discussion avec l’entrepreneur
            </option>
            <option value="Je ne compte pas le faire pour l’instant">
              Je ne compte pas le faire pour l’instant
            </option>
          </select>
        </p>
      </div>
      <h3>
        Où les travaux doivent-ils avoir lieu ? <span className="obg">*</span>
      </h3>
      <div className="element ">
        <p className="sub-50">
          <span className="title" htmlFor="rue">
            Rue <span className="obg"> *</span>
          </span>
          <input type="text" name="rue" arial-label="rue" required />
        </p>
        <p>
          <span className="title" htmlFor="numero">
            Numéro <span className="obg"> *</span>
          </span>
          <input type="text" name="numero" arial-label="numero" required />
        </p>
        <p>
          <span className="title" htmlFor="boite">
            Boite
          </span>
          <input type="text" name="boite" arial-label="boite" />
        </p>
      </div>
      <div className="element ">
        <p>
          <span className="title" htmlFor="code">
            Code Postale <span className="obg"> *</span>
          </span>
          <input type="text" name="code" arial-label="code" required />
        </p>
        <p className="sub-75">
          <span className="title" htmlFor="ville">
            Ville <span className="obg"> *</span>
          </span>
          <input type="text" name="ville" arial-label="ville" required />
        </p>
      </div>
      <h3>
        Situation
        <span className="obg"> *</span>
      </h3>
      <div className="element">
        <p>
          <select name="situation" aria-label="heure" required>
            <option value="Oui, je suis propriétaire">
              Oui, je suis propriétaire
            </option>
            <option value="Non, je suis locataire">
              Non, je suis locataire
            </option>
            <option value="Non, je serai propriétaire dans les 3 mois">
              Non, je serai propriétaire dans les 3 mois
            </option>
            <option value="Non, je serai propriétaire dans plus de 3 mois">
              Non, je serai propriétaire dans plus de 3 mois
            </option>
            <option value="Non, j’envisage d’acheter">
              Non, j’envisage d’acheter
            </option>
          </select>
        </p>
      </div>
    </div>
  )
}

export default Travaux
