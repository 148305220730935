import React from "react"

const Coordonnee = () => {
  return (
    <div className="fieldset">
      <div className="element">
        <p>
          <span className="title" htmlFor="type" required>
            Type <span className="obg">*</span>
          </span>
          <select name="type" aria-label="type" required>
            <option value="particulier">Particulier</option>
            <option value="entreprise">Entreprise</option>
          </select>
        </p>
      </div>
      <div className="element element-2">
        <p>
          <span className="title" htmlFor="nom">
            Nom <span className="obg">*</span>
          </span>
          <input type="text" name="nom" arial-label="nom" required />
        </p>
        <p>
          <span htmlFor="prenom" className="title">
            Prénom <span className="obg">*</span>
          </span>
          <input type="text" name="prenom" arial-label="prenom" required />
        </p>
      </div>
      <div className="element">
        <p>
          <span className="title" htmlFor="email">
            Email <span className="obg">*</span>
          </span>
          <input type="email" name="email" arial-label="email" required />
        </p>
      </div>
      <div className="element element-2">
        <p>
          <span className="title" htmlFor="telephone">
            Téléphone <span className="obg">*</span>
          </span>
          <input
            type="text"
            name="telephone"
            arial-label="telephone"
            required
          />
        </p>
        <p>
          <span className="title" htmlFor="telephone-facultatif">
            Téléphone supplémentaire
          </span>
          <input
            type="text"
            name="telephone-facultatif"
            arial-label="telephone-facultatif"
          />
        </p>
      </div>
    </div>
  )
}

export default Coordonnee
