import React, { useState } from "react"
//components
import Paint2 from "../Projet/Paint2"
import Paint3 from "../Projet/Paint3"

const Peinture = () => {
  const [paint1, setPaint1] = useState(false)
  const [paint2, setPaint2] = useState(false)
  const [paint3, setPaint3] = useState(false)

  const checkPaint1 = () => {
    setPaint1(paint1 => true)
    setPaint2(paint2 => false)
    setPaint3(paint3 => false)
  }
  const checkPaint2 = () => {
    setPaint1(paint1 => false)
    setPaint2(paint2 => true)
    setPaint3(paint3 => false)
  }
  const checkPaint3 = () => {
    setPaint1(paint1 => false)
    setPaint2(paint2 => false)
    setPaint3(paint3 => true)
  }
  return (
    <div className="subService">
      <h3>
        Pour quel type de peinture / Pour quelle technique voulez-vous recevoir
        des devis? <span className="obg"> *</span>
      </h3>
      <p>
        <label>
          <input
            type="radio"
            name="type-peinture"
            value="Peinture de façade qualitative (peinture acrylate, peinture minérale, peintures siloxanes,...)"
            onClick={() => {
              checkPaint1()
            }}
            required
          />
          Peinture de façade qualitative (peinture acrylate, peinture minérale,
          peintures siloxanes,...)
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="type-peinture"
            value="Enduit à la chaux"
            onClick={() => {
              checkPaint2()
            }}
            required
          />
          Enduit à la chaux
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="type-peinture"
            value="Liège pulvérisé"
            onClick={() => {
              checkPaint3()
            }}
            required
          />
          Liège pulvérisé
        </label>
      </p>
      {paint1 ? "" : paint2 ? <Paint2 /> : paint3 ? <Paint3 /> : ""}
    </div>
  )
}

export default Peinture
