import React from "react"

const Recontact = () => {
  return (
    <div className="fieldset">
      <h3>
        Quand pouvons nous vous contacter ? <span className="obg">*</span>
      </h3>
      <div className="element">
        <p>
          <select name="heure" aria-label="heure" required>
            <option value="avant-midi">Avant midi</option>
            <option value="après-midi">Après midi</option>
            <option value="le soir">Le soir</option>
            <option value="le week-end">Le week-end </option>
          </select>
        </p>
      </div>
    </div>
  )
}

export default Recontact
