import React from "react"

const Reno1 = () => {
  return (
    <div className="subService">
      <h3>
        Quelle est la surface des travaux de jointoiement à réaliser?
        <span className="obg"> *</span>
      </h3>
      <select name="surface-jointement" aria-label="type" required>
        <option value="Moins de 10 m²">Moins de 10 m²</option>
        <option value="Entre 10 et 50 m²">Entre 10 et 50 m²</option>
        <option value="Entre 50 et 100 m²">Entre 50 et 100 m²</option>
        <option value="Plus de 100 m²">Plus de 100 m²</option>
      </select>
    </div>
  )
}

export default Reno1
