import React, { useState } from "react"
//components
import Multi1 from "../Projet/Multi1"
import Multi2 from "../Projet/Multi2"
import Multi3 from "../Projet/Multi3"
import Multi4 from "../Projet/Multi4"
import Multi5 from "../Projet/Multi5"
import Multi6 from "../Projet/Multi6"

const Multi = () => {
  const [multi1, setMulti1] = useState(false)
  const [multi2, setMulti2] = useState(false)
  const [multi3, setMulti3] = useState(false)
  const [multi4, setMulti4] = useState(false)
  const [multi5, setMulti5] = useState(false)
  const [multi6, setMulti6] = useState(false)

  const checkMulti1 = () => {
    setMulti1(multi1 => true)
    setMulti2(multi2 => false)
    setMulti3(multi3 => false)
    setMulti4(multi4 => false)
    setMulti5(multi5 => false)
    setMulti6(multi6 => false)
  }
  const checkMulti2 = () => {
    setMulti1(multi1 => false)
    setMulti2(multi2 => true)
    setMulti3(multi3 => false)
    setMulti4(multi4 => false)
    setMulti5(multi5 => false)
    setMulti6(multi6 => false)
  }
  const checkMulti3 = () => {
    setMulti1(multi1 => false)
    setMulti2(multi2 => false)
    setMulti3(multi3 => true)
    setMulti4(multi4 => false)
    setMulti5(multi5 => false)
    setMulti6(multi6 => false)
  }
  const checkMulti4 = () => {
    setMulti1(multi1 => false)
    setMulti2(multi2 => false)
    setMulti3(multi3 => false)
    setMulti4(multi4 => true)
    setMulti5(multi5 => false)
    setMulti6(multi6 => false)
  }
  const checkMulti5 = () => {
    setMulti1(multi1 => false)
    setMulti2(multi2 => false)
    setMulti3(multi3 => false)
    setMulti4(multi4 => false)
    setMulti5(multi5 => true)
    setMulti6(multi6 => false)
  }
  const checkMulti6 = () => {
    setMulti1(multi1 => false)
    setMulti2(multi2 => false)
    setMulti3(multi3 => false)
    setMulti4(multi4 => false)
    setMulti5(multi5 => false)
    setMulti6(multi6 => true)
  }
  return (
    <div className="subService">
      <h3>
        Quels matériaux considérez-vous pour revêtir la façade?
        <span className="obg"> *</span>
      </h3>
      <div className="element">
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Crépi"
              onClick={() => {
                checkMulti1()
              }}
              required
            />
            Crépi
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Plâtre de ciment"
              onClick={() => {
                checkMulti1()
              }}
            />
            Plâtre de ciment
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Liège projeté"
              onClick={() => {
                checkMulti1()
              }}
            />
            Liège projeté
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Bardage de façade avec des plaquettes de parement"
              onClick={() => {
                checkMulti1()
              }}
            />
            Bardage de façade avec des plaquettes de parement
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Bardage de façade en bois"
              onClick={() => {
                checkMulti2()
              }}
            />
            Bardage de façade en bois
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Bardage de façade métallique"
              onClick={() => {
                checkMulti3()
              }}
            />
            Bardage de façade métallique
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Fibrociment"
              onClick={() => {
                checkMulti1()
              }}
            />
            Fibrociment (e.a. eternit, cembrit,...) = ardoises, panneaux,
            sidings,...
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="bardage de façade en PVC = sidings en matière synthétique"
              onClick={() => {
                checkMulti1()
              }}
            />
            Bardage de façade en PVC = sidings en matière synthétique
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Composite (e.a. rockpanel,...) = sidings, panneaux"
              onClick={() => {
                checkMulti1()
              }}
            />
            Composite (e.a. rockpanel,...) = sidings, panneaux
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Bardage de façade en pierre naturelle"
              onClick={() => {
                checkMulti1()
              }}
            />
            Bardage de façade en pierre naturelle
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="HPL (= Trespa, volkern,...) = panneaux"
              onClick={() => {
                checkMulti1()
              }}
            />
            HPL (= Trespa, volkern,...) = panneaux
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Panneaux de laine de roche comprimés (= e.a. rockwool,...)"
              onClick={() => {
                checkMulti1()
              }}
            />
            Panneaux de laine de roche comprimés (= e.a. rockwool,...)
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Enduit à la chaux"
              onClick={() => {
                checkMulti4()
              }}
            />
            Enduit à la chaux
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Béton architectonique"
              onClick={() => {
                checkMulti5()
              }}
            />
            Béton architectonique
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Bardage de façade en céramique"
              onClick={() => {
                checkMulti1()
              }}
            />
            Bardage de façade en céramique
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="materiaux-facade"
              value="Maçonner nouvelle façade"
              onClick={() => {
                checkMulti6()
              }}
            />
            Maçonner nouvelle façade
          </label>
        </p>
      </div>
      {multi1 ? (
        <Multi1 />
      ) : multi2 ? (
        <Multi2 />
      ) : multi3 ? (
        <Multi3 />
      ) : multi4 ? (
        <Multi4 />
      ) : multi5 ? (
        <Multi5 />
      ) : multi6 ? (
        <Multi6 />
      ) : (
        ""
      )}
    </div>
  )
}

export default Multi
