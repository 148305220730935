import React from "react"

//components
import Projet from "../fr/fieldset/Projet"

import Travaux from "../fr/fieldset/Travaux"
import Coordonnee from "../fr/fieldset/Coordonnee"
import Recontact from "../fr/fieldset/Recontact"
import Validation from "../fr/fieldset/Validation"
import LinkPrime from "../fr/fieldset/LinkPrime"

const DevisFr = () => {
  return (
    <form
      method="post"
      action="https://www.flexyform.com/f/580f7fe45e128eecf4d8c7b18e95078514322ddf"
    >
      <h2>Décrivez votre projet</h2>
      <Projet />
      <Travaux />
      <h2>Coordonnées</h2>
      <Coordonnee />
      <Recontact />
      <Validation />
      <LinkPrime />
      <input type="hidden" name="_recaptcha" id="_recaptcha" />
      <script src="https://www.google.com/recaptcha/api.js?render=6Lf7UsoUAAAAACT2Z6gLyh7RTDfyYGxfZ-M4D0ph"></script>
      <script src="https://www.flexyform.com/js/recaptcha.js"></script>

      <div className="send">
        <button type="submit" className="button">
          Envoyer
        </button>
      </div>
    </form>
  )
}

export default DevisFr
