import React from "react"
import { graphql } from "gatsby"
//components
import Section from "../components/Section"
import SEO from "../components/SEO"
import DevisFr from "../components/Devis/fr/DevisFr"
//css
import "../css/devis.css"
const DevisPage = ({ pageContext, data }) => {
  return (
    <>
      {data.c.metaDsc ? (
        <SEO
          title={data.c.title}
          dsc={data.c.metaDsc.metaDsc}
          pageContext={pageContext}
        />
      ) : (
        <SEO title={data.c.title} pageContext={pageContext} />
      )}

      <Section classname="devis" container>
        {pageContext.node_locale === "fr" ? <DevisFr /> : ""}
      </Section>
    </>
  )
}
export const query = graphql`
  query($contentful_id: String, $node_locale: String) {
    c: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      metaDsc {
        metaDsc
      }
    }
  }
`

export default DevisPage
