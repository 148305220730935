import React, { useState } from "react"
//components
import Reno1 from "../Projet/Reno1"
import Reno2 from "../Projet/Reno2"
import Reno3 from "../Projet/Reno3"
import Reno4 from "../Projet/Reno4"
const Reno = () => {
  const [reno1, setReno1] = useState(false)
  const [reno2, setReno2] = useState(false)
  const [reno3, setReno3] = useState(false)
  const [reno4, setReno4] = useState(false)

  const checkReno1 = () => {
    setReno1(reno1 => true)
    setReno2(reno2 => false)
    setReno3(reno3 => false)
    setReno4(reno4 => false)
  }
  const checkReno2 = () => {
    setReno1(reno1 => false)
    setReno2(reno2 => true)
    setReno3(reno3 => false)
    setReno4(reno4 => false)
  }
  const checkReno3 = () => {
    setReno1(reno1 => false)
    setReno2(reno2 => false)
    setReno3(reno3 => true)
    setReno4(reno4 => false)
  }
  const checkReno4 = () => {
    setReno1(reno1 => false)
    setReno2(reno2 => false)
    setReno3(reno3 => false)
    setReno4(reno4 => true)
  }
  return (
    <div className="subService">
      <h3>
        Quels travaux de réparation doivent être effectués?
        <span className="obg"> *</span>
      </h3>
      <p>
        <label>
          <input
            type="radio"
            name="quel-reparation"
            value="Réparer les joints (meuler et rejointoyer)"
            onClick={() => {
              checkReno1()
            }}
            required
          />
          Réparer les joints (meuler et rejointoyer)
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="quel-reparation"
            value="Rénovation générale de la façade"
            onClick={() => {
              checkReno2()
            }}
          />
          Rénovation générale de la façade
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="quel-reparation"
            value="Restaurer une façade avec des ornements"
            onClick={() => {
              checkReno3()
            }}
          />
          Restaurer une façade avec des ornements
        </label>
      </p>
      <p>
        <label>
          <input
            type="radio"
            name="quel-reparation"
            value="Hydrofuger la façade"
            onClick={() => {
              checkReno4()
            }}
          />
          Hydrofuger la façade
        </label>
      </p>
      {reno1 ? (
        <Reno1 />
      ) : reno2 ? (
        <Reno2 />
      ) : reno3 ? (
        <Reno3 />
      ) : reno4 ? (
        <Reno4 />
      ) : (
        ""
      )}
    </div>
  )
}

export default Reno
