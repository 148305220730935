import React from "react"

const Multi3 = () => {
  return (
    <div className="subService">
      <h3>
        Quelle est la surface à revêtir?
        <span className="obg"> *</span>
      </h3>
      <select name="surafe-facade" required>
        <option value="Moins de 10 m²">Moins de 10 m²</option>
        <option value="Entre 10 et 50 m²">Entre 10 et 50 m²</option>
        <option value="Entre 50 et 100 m²">Entre 50 et 100 m²</option>
        <option value="Plus de 100 m²">Plus de 100 m²</option>
      </select>
      <h3>
        Voulez-vous aussi faire isoler les murs?
        <span className="obg"> *</span>
      </h3>
      <select name="isoler-mur" required>
        <option value="oui / peut-être">oui / peut-être</option>
        <option value="non">non</option>
      </select>
      <h3>
        Par quels types de métal êtes-vous intéressé?
        <span className="obg"> *</span>
      </h3>
      <select name="type-metal" required>
        <option value="aluminium laqué">aluminium laqué</option>
        <option value="acier laqué">acier laqué</option>
        <option value="acier corten (couleur rouille)">
          acier corten (couleur rouille)
        </option>
        <option value="aluminium anodisé">aluminium anodisé</option>
        <option value="zinc">zinc</option>
        <option value="cuivre">cuivre</option>
      </select>
    </div>
  )
}

export default Multi3
