import React, { useState } from "react"

//components
import Multi from "../fieldset/Projet/Multi"
import Reno from "../fieldset/Projet/Reno"
import Net from "../fieldset/Projet/Net"
import Iso from "../fieldset/Projet/Iso"
import Paint from "../fieldset/Projet/Paint"
import Hydro from "../fieldset/Projet/Hydro"

const Projet = () => {
  const [multi, setMulti] = useState(false)
  const [reno, setReno] = useState(false)
  const [nettoyage, setNettoyage] = useState(false)
  const [isolation, setIsolation] = useState(false)
  const [peinture, setPeinture] = useState(false)
  const [hydro, setHydro] = useState(false)

  const checkMulti = () => {
    setMulti(multi => true)
    setReno(reno => false)
    setNettoyage(nettoyage => false)
    setIsolation(isolation => false)
    setPeinture(peinture => false)
    setHydro(hydro => false)
  }
  const checkReno = () => {
    setMulti(multi => false)
    setReno(reno => true)
    setNettoyage(nettoyage => false)
    setIsolation(isolation => false)
    setPeinture(peinture => false)
    setHydro(hydro => false)
  }
  const checkNettoyage = () => {
    setMulti(multi => false)
    setReno(reno => false)
    setNettoyage(nettoyage => true)
    setIsolation(isolation => false)
    setPeinture(peinture => false)
    setHydro(hydro => false)
  }
  const checkIsolation = () => {
    setMulti(multi => false)
    setReno(reno => false)
    setNettoyage(nettoyage => false)
    setIsolation(isolation => true)
    setPeinture(peinture => false)
    setHydro(hydro => false)
  }
  const checkPeinture = () => {
    setMulti(multi => false)
    setReno(reno => false)
    setNettoyage(nettoyage => false)
    setIsolation(isolation => false)
    setPeinture(peinture => true)
    setHydro(hydro => false)
  }
  const checkHydro = () => {
    setMulti(multi => false)
    setReno(reno => false)
    setNettoyage(nettoyage => false)
    setIsolation(isolation => false)
    setPeinture(peinture => false)
    setHydro(hydro => true)
  }
  return (
    <div className="fieldset">
      <h3>
        Quels travaux voulez-vous faire effectuer?
        <span className="obg"> *</span>
      </h3>
      <div className="element">
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="projet"
              value="bardage ou enduit de façade"
              onClick={() => {
                checkMulti()
              }}
            />
            Bardage ou enduit de façade
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="projet"
              value="Rénover et/ou réparer façade"
              onClick={() => {
                checkReno()
              }}
            />
            Rénover et/ou réparer façade
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="projet"
              value="Nettoyer façade"
              onClick={() => {
                checkNettoyage()
              }}
            />
            Nettoyer façade
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="projet"
              value="Isoler façade"
              onClick={() => {
                checkIsolation()
              }}
            />
            Isoler façade
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="projet"
              value="Peindre façade"
              onClick={() => {
                checkPeinture()
              }}
            />
            Peindre façade
          </label>
        </p>
        <p className="sub-100">
          <label>
            <input
              type="radio"
              name="projet"
              value="Hydrofuger la façade"
              onClick={() => {
                checkHydro()
              }}
              required
            />
            Hydrofuger la façade
          </label>
        </p>
      </div>
      {multi ? (
        <Multi />
      ) : reno ? (
        <Reno />
      ) : nettoyage ? (
        <Net />
      ) : isolation ? (
        <Iso />
      ) : peinture ? (
        <Paint />
      ) : hydro ? (
        <Hydro />
      ) : (
        ""
      )}
      <h3>
        Explication de la situation actuelle et des travaux voulus
        <span className="obg"> *</span>
      </h3>
      <div className="element">
        <p>
          <textarea required name="explication"></textarea>
        </p>
      </div>
    </div>
  )
}

export default Projet
